<script lang="ts" setup>
const { slicedGames, menu, showLoadMoreButton, favorites, load, handleLoadMoreClick } = useGamesCatalog();
const { gameName, isExpanded } = useGameSearch();

defineProps<{
	showCatalog: boolean;
}>();

const observerElement = ref<HTMLElement | null>(null);
const gamesConteiner = ref<HTMLElement | null>(null);
let observer: IntersectionObserver | null = null;
const activeTab = ref("lobby");

const scrollIntoCatalog = () => {
	if (!isClient) {
		return;
	}

	if (window?.scrollY > (gamesConteiner.value?.offsetTop ?? 0)) {
		window.scrollTo({
			top: gamesConteiner.value?.offsetTop,
			behavior: "smooth"
		});
	}
};

const handleClickTab = (link: string) => {
	const item = link.split("/")[2];
	activeTab.value = item;
	scrollIntoCatalog();

	if (item === "lobby") {
		return;
	}

	load(item);
};

onMounted(() => {
	const activeUrl = useState("active-url-games");
	activeUrl.value = "/";
	gameName.value = "";
	isExpanded.value = false;

	observer = new IntersectionObserver(
		(entries) => {
			if (entries?.[0]?.isIntersecting && showLoadMoreButton.value) {
				handleLoadMoreClick();
			}
		},
		{
			root: null,
			rootMargin: "200px",
			threshold: [0.5]
		}
	);

	if (observerElement.value) {
		observer?.observe(observerElement.value);
	}
});

onUnmounted(() => {
	if (observer) {
		observer.disconnect();
	}
});
</script>

<template>
	<div ref="gamesConteiner" class="gamesConteiner" />
	<div :class="['catalog', { fixed: !showCatalog }]">
		<OGamesNavigation :menu="menu" type="button" show-lobby class="wrapper" @click-tab="handleClickTab" />
		<template v-if="showCatalog">
			<LazyOGames v-if="slicedGames?.length && (favorites?.length || activeTab !== 'favorites')" :games="slicedGames" />
			<template v-if="activeTab === 'favorites' && !favorites?.length">
				<MGamesFavoriteEmpty />
				<OGameSlider
					v-if="slicedGames?.length"
					class="section-item row-games"
					:games="slicedGames"
					title="Recently Played"
					icon="24/recent"
				/>
			</template>
		</template>

		<div ref="observerElement" class="border" />
	</div>
</template>

<style scoped lang="scss">
.wrapper {
	width: 100%;

	&:deep(+ *:not(.border)) {
		padding-top: 24px;

		@include media-breakpoint-down(lg) {
			padding-top: 8px;
		}
	}
}

.gamesConteiner {
	@include media-breakpoint-down(lg) {
		margin-bottom: -32px;
	}
}

.fixed {
	position: sticky;
	top: var(--top-height);
	z-index: 3;
}

.catalog {
	width: 100%;

	&:deep(.fixed + .section-item) {
		padding-top: 20px;
	}

	&:deep(.grid-cards) {
		margin-bottom: 32px;
	}
}

.favorite-empty {
	padding-bottom: 32px;
}
</style>
